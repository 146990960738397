// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aml-policy-js": () => import("./../../../src/pages/aml_policy.js" /* webpackChunkName: "component---src-pages-aml-policy-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie_policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-exchange-buy-js": () => import("./../../../src/pages/exchange_buy.js" /* webpackChunkName: "component---src-pages-exchange-buy-js" */),
  "component---src-pages-exchange-deposit-js": () => import("./../../../src/pages/exchange_deposit.js" /* webpackChunkName: "component---src-pages-exchange-deposit-js" */),
  "component---src-pages-exchange-js": () => import("./../../../src/pages/exchange.js" /* webpackChunkName: "component---src-pages-exchange-js" */),
  "component---src-pages-exchange-order-history-js": () => import("./../../../src/pages/exchange_order_history.js" /* webpackChunkName: "component---src-pages-exchange-order-history-js" */),
  "component---src-pages-exchange-sell-js": () => import("./../../../src/pages/exchange_sell.js" /* webpackChunkName: "component---src-pages-exchange-sell-js" */),
  "component---src-pages-exchange-swap-js": () => import("./../../../src/pages/exchange_swap.js" /* webpackChunkName: "component---src-pages-exchange-swap-js" */),
  "component---src-pages-identity-js": () => import("./../../../src/pages/identity.js" /* webpackChunkName: "component---src-pages-identity-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-my-identity-js": () => import("./../../../src/pages/my_identity.js" /* webpackChunkName: "component---src-pages-my-identity-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms_and_conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-wallet-balances-js": () => import("./../../../src/pages/wallet_balances.js" /* webpackChunkName: "component---src-pages-wallet-balances-js" */),
  "component---src-pages-wallet-js": () => import("./../../../src/pages/wallet.js" /* webpackChunkName: "component---src-pages-wallet-js" */),
  "component---src-pages-wallet-receive-js": () => import("./../../../src/pages/wallet_receive.js" /* webpackChunkName: "component---src-pages-wallet-receive-js" */),
  "component---src-pages-wallet-send-js": () => import("./../../../src/pages/wallet_send.js" /* webpackChunkName: "component---src-pages-wallet-send-js" */),
  "component---src-pages-wallet-transactions-js": () => import("./../../../src/pages/wallet_transactions.js" /* webpackChunkName: "component---src-pages-wallet-transactions-js" */)
}

